<template>
  <div class="scan-qr">
    <div class="scan-qr__name">
      <h2>Наведите камеру на QR - код</h2>
    </div>

    <div class="scan-qr__camera">
      <div class="scan-qr__scanner">
        <qrcode-stream
          @detect="onDetect"
          :camera="camera"
          :track="paintOutline"
          @init="onInit"
        >
          <img
            src="../assets/images/red-dot.png"
            class="scan-qr__red-dot"
            alt="red dot"
          />
        </qrcode-stream>
      </div>
    </div>

    <div class="scan-qr__description">
      <p class="scan-qr__info">
        Убедитесь, что Ваше устройство находится на достаточном расстоянии от QR
        - кода и наведите камеру устройства на него,
      </p>
    </div>
    <modal-warn
      :message="error"
      dismissButtonValue="Закрыть"
      :isModalVisible="isErrorVisible"
      @modal-dismissed="dismissError"
    ></modal-warn>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import ModalWarn from "@/components/shared/components/modals/ModalWarn.vue";

export default {
  name: "ScanQr",

  components: {
    QrcodeStream,
    ModalWarn,
  },

  props: {
    point: { type: Object, default: () => {} },
  },

  data: () => ({
    resultScan: "",
    error: "",
    camera: "auto",
    position: null,
    isErrorVisible: false,
  }),

  methods: {
    paintOutline(detectedCodes, ctx) {
      {
        const [firstPoint, ...otherPoints] = Object.values(detectedCodes);

        ctx.strokeStyle = "#56DA39";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);

        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }

        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    init() {
      // this.getGeoPosition();
    },

    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    getGeoPosition() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Геолокация недоступна на этом устройстве"));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const coords = pos.coords;
            const { latitude, longitude } = coords;
            this.position = [latitude, longitude];
            resolve(pos);
          },
          (err) => {
            this.error =
              "Для корректной работы приложения требуется отслеживание геолокации. Пожалуйста, разрешите доступ к геолокации в настройках вашего устройства.";
            this.isErrorVisible = true;
            reject(err);
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 5000,
          }
        );
      });
    },

    async onDetect(promise) {
      const { commit, dispatch } = this.$store;
      await this.getGeoPosition();
      try {
        const { content } = await promise;
        this.pause();
        if (this.position) {
          commit("setLoader", true);
          const data = {
            waypoint_id: this.point.id,
            route_id: Number(this.$route.path.match(/[0-9]{1,2}/g)[0]),
            user_position: this.position.join(", "),
            uuid: content,
          };

          const response = await dispatch("sendQrData", data);

          commit("setLoader", false);
          commit("setScanResult", response.data);
          this.$nextTick(() => {
            this.$router.replace({ path: `result` });
          });
        } else {
          this.unpause();
          await this.getGeoPosition();
        }
      } catch (error) {
        commit("setLoader", false);
        this.unpause();
        this.error = error;
        this.isErrorVisible = true;
      }
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error =
            "ОШИБКА: вам необходимо предоставить разрешение на доступ к камере";
        } else if (error.name === "NotFoundError") {
          this.error = "ОШИБКА: на этом устройстве нет камеры";
        } else if (error.name === "NotSupportedError") {
          this.error = "ОШИБКА: требуется безопасный контекст";
        } else if (error.name === "NotReadableError") {
          this.error = "ОШИБКА: камера уже используется?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ОШИБКА: установленные камеры не подходят";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ОШИБКА: Stream API не поддерживается в этом браузере";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ОШИБКА: Доступ к камере разрешен только в безопасном контексте.";
        } else {
          this.error = `ОШИБКА: ошибка камеры: (${error.name})`;
        }
        this.isErrorVisible = true;
      }
    },
    dismissError() {
      this.isErrorVisible = false;
      this.unpause();
      this.getGeoPosition();
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.scan-qr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;

  &__name {
    padding: 0 40px;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }

  &__camera {
    margin-bottom: 4px;
    width: 100%;
  }

  &__info {
    font-size: 17px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }

  &__camera,
  &__scanner {
    height: 380px;
  }

  &__red-dot {
    width: 1px;
    height: 1px;
    margin: 185px auto 0 auto;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: red-dot-scale;
  }
}

@keyframes red-dot-scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>
