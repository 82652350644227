<template>
  <div class="page-main" :class="'background-main'">
    <div class="page-main__inner">
      <div class="page-main__content">
        <div class="page-main__title">
          <img :src="require('../assets/images/iwalk_new_logo.png')" alt="" />
        </div>
      </div>
      <div class="page-main__footer">
        <div @click="goToNextPage" class="page-main__action">
          <p>Принять участие</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPage",

  methods: {
    goToNextPage() {
      this.$router.push({ name: "description" });
    },

    init() {
      this.fetchUserData();
    },

    async fetchUserData() {
      const { dispatch, commit } = this.$store;

      try {
        commit("setLoader", true);
        const response = await dispatch("fetchUserData");
        commit("setLoader", false);

        if (response.status) {
          this.$router.push({ name: "profile" });
        }
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.page-main {
  height: 100vh;

  &__inner {
    height: 100%;
    padding: 56px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__header {
    margin-top: 30px;
  }

  &__footer {
    width: 100%;
    height: 65px;
  }

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: #0c2248;
    font-weight: 900;
    font-size: 45px;
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  &__subtitle {
    font-size: 28px;
  }

  &__action {
    position: relative;
    border-radius: 20px;
    width: 302px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #56da39;

    p {
      font-weight: 700;
      line-height: 32.68px;
      font-size: 22px;
      color: #fff;
    }
  }
}
</style>
