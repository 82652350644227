<template>
  <div class="result-page">
    <div class="result-page__inner">
      <div class="result-page__content">
        <div class="result-page__title">
          <h2>{{ getContent.title }}</h2>
        </div>

        <div class="result-page__status">
          <result-status :status="getScanResult.status" />
        </div>

        <div class="result-page__message">
          <h2>{{ getContent.messages }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultStatus from "@/components/ResultStatus.vue";

export default {
  name: "ResultPage",

  components: {
    ResultStatus,
  },

  data() {
    return {
      scanResult: this.$store.getters.scan,
      handleStatusList: [201, 404, 405, 406, 500]
    }
  },

  computed: {
    getScanResult() {
      const isHandleStatus = this.scanResult?.status ? this.handleStatusList.includes(this.scanResult.status) : false;
      if(!isHandleStatus) return {status: 10};
      return this.scanResult;
    },

    getContent() {
      return {
        10: {
          title: "Не удалось получить данные сканирования",
          messages: "Пожалуйста, попробуйте ещё раз."
        },
        201: {
          title: "Сканирование успешно завершено!",
          messages: "",
        },
        404: {
          title: "Не удалось зарегестрировать прохождение",
          messages: "Возможно, вы отсканировали qr другого объекта или уже прошли эту точку ранее"
        },
        405: {
          title: "Некорректный QR-код",
          messages:
            "Отсканирован некорректный QR-код. Убедитесь, что вы сканируете нужный QR-код, указанный в точке.",
        },
        406: {
          title: "Вы находитесь вне зоны объекта",
          messages:
            "Во время сканирования QR-кода необходимо находиться не дальше чем за 100 метров от точки.",
        },
        500: {
          title: "Неверный QR-код.",
          messages: "Отсканируйте код с таблички с логотипом «Я шагаю».",
        },
      }[this.getScanResult.status];
    },
  },
};
</script>

<style lang="scss">
.result-page {
  height: 100vh;

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 120px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
  }

  &__message {
    font-size: 17px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }
}
</style>
