<template>
    <div class="award__title">
        <p class="award__title-content">{{ awardTitle }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            awardTitle: String,
        }
    }
</script>

<style lang="scss">
    .award {
        &__title {
            &-content {
                font-size: 18px;
                line-height: 20px;
                font-weight: 700;
                word-break: break-word;
            }
        }
    }
</style>