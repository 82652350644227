/** @see https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError */
const errorCodeMessages = {
  1: "Для корректной работы приложения требуется отслеживание геолокации. Пожалуйста, разрешите доступ к геолокации в настройках вашего устройства.",
  2: "Ошибка получения геолокации. Попробуйте eщё раз.",
  3: "Ошибка получения геолокации. Попробуйте eщё раз. (TIMEOUT)",
};

/**
 * @returns {Promise<string>} геолокация пользователя в формате "lat, long"
 */
export async function getCurrentGeolocation() {
  return new Promise((resolve, reject) => {
    if (!("geolocation" in navigator)) {
      reject(new Error("Геолокация недоступна на этом устройстве"));
    }

    navigator.geolocation.getCurrentPosition(
      (pos) => resolve(`${pos.coords.latitude}, ${pos.coords.longitude}`),
      (err) => {
        console.error("error getting geolocation from Geolocation API");
        console.error(err);
        let message = errorCodeMessages[err.code];
        reject(new Error(message));
      },
      {
        enableHighAccuracy: true,
        maximumAge: 5000,
        timeout: 5000,
      }
    );
  });
}
