<template>
  <div class="modal" :class="{'modal_active': isModalVisible}">
    <div
    class="modal__container"
    :class="{'modal__container_active': isModalVisible}"
    >
        <p v-if="message" class="modal__container_message">
          {{ message }}
        </p>
        <slot></slot>
        <button
          @click="handleDismissModal"
          class="modal__container-button_success"
        >
          {{ dismissButtonValue }}
        </button>
    </div>
  </div>
</template>

<script>
    export default {
        name: "ModalSuccess",
        props: ['message', 'dismissButtonValue', 'isModalVisible'],
        watch: {
            message: function(value) {
                return value;
            },
            dismissButtonValue: function(value) {
                return value;
            },
            isModalVisible: function(value) {
                return value;
            },
        },
        methods: {
            handleDismissModal(event) {
                this.$emit('modal-dismissed');
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../../../styles/variables";
.modal {
  position: relative;
  z-index: 20;

  &_active {
    &::before {
          content: 'hello';
          display: flex;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-color: $color-black;
          opacity: .5;
        }
  }

  &__container {
    display: none;
    width: 100%;
    z-index: 21;
    &_active {
      display: flex;
      position: fixed;
      top: 30%;
      left: 10%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $color-very-light-gray;
      word-wrap: break-word;
      border-radius: 10px;
      padding: 20px;
      width: 80%;
      min-width: 100px;
      height: auto;
      min-height: 200px;
    }
    &_message {
      word-break: break-all;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: $color-positive;
      margin: 20px auto;
      font-weight: 700;
    }
    &-button {
      &_success {
          font-size: 13px;
          color: $color-positive;
          font-weight: 700;
          background-color: $color-white;
          border-radius: 20px;
          width: 100%;
          height: 100%;
          max-width: 123px;
          min-height: 46px;
          max-height: 46px;
          filter: drop-shadow(0 4px 14px rgba($color-blue-shadow, 0.1));
      }
    }
  }
}
</style>