<template>
  <div class="award">
    <div class="award__inner">
      <div class="award__left">
        <div class="award__content">
          <award-image-wrapper :imgWrapperHeight="imgWrapperHeight" :titleImgUrl="titleImgUrl" :isNoImages="isNoImages">
            <template v-slot:preload>
              <div class="award__preload">
                <pre-loader :isLoading="isPhotoLoading"></pre-loader>
              </div>
            </template>
          </award-image-wrapper>
        </div>

      </div>
      <div class="award__rigth">
        <div class="award__price">
            <slot name="footer" />
          </div>
      </div>
    </div>
    <award-title class="award__title" :awardTitle="award.product_name"></award-title>
  </div>
</template>

<script>

import PreLoader from "../shared/components/PreLoader.vue";
import AwardImageWrapper from "./award-image-wrapper/AwardImageWrapper.vue";
import AwardTitle from "./award-title/AwardTitle.vue";

export default {
  name: "Award",

  data: () => ({
    titleImgUrl: '',
    isPhotoLoading: false,
    isNoImages: false,
  }),

  props: {
    award: {
      type: Object,
      default: () => {},
    },
    imgWrapperHeight: String
  },
  
  components: {
    PreLoader,
    AwardImageWrapper,
    AwardTitle
  },

  created() {
      this.getTitleImageUrl()
  },

  watch: {
    award() {
      this.getTitleImageUrl();
    }
  },

  methods: {
    async getTitleImageUrl() {
      if (this.award.images?.length) {
        const { dispatch } = this.$store;
        try {
          this.isPhotoLoading = true
          const photo = await dispatch("fetchImage", {
                  image: this.award.images[0].image
                })
          this.isPhotoLoading = false;
          this.isNoImages = false;
          this.titleImgUrl = photo;
        } catch (e) {
          console.warn(e);
          this.isPhotoLoading = false
          this.isNoImages = true;
        }
      }  else {
        this.titleImgUrl = '';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.award {
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__inner {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    margin-top: 20px;
  }

  &__price {
    padding-top: 8px;

    &-content {
      color: #ff8a00;
      font-weight: bold;
      font-size: 21px;
      line-height: 25.41px;
    }
  }

  &__description {
    &-content {
      font-size: 15px;
      line-height: 18.15px;
    }
  }

  &__actions {
    margin: 61px 0 0 0;
  }

  &__image {
    width: 100%;
    max-width: 300px;
    object-fit: contain;

    &-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    &_out {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 160px;
      min-height: 184px;
      font-size: 15px;
    }
  }

  &__preload {
    position: absolute;
    top: 25%;
    left: 30%
  }
}
</style>
