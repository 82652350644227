import { set } from "lodash";
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { getCurrentGeolocation } from "@/services/geolocationApi";

Vue.use(Vuex);

const SERVER = process.env.VUE_APP_URL;

function API(jwt_token) {
  const { headers } = axios.defaults;
  const token = "F5O6PyTFRshaAOsHYTXLiHdnwkIkEfOP08Bs8UKg";

  if (token) {
    headers.common["X-CSRF-TOKEN"] = token;
  } else {
    console.error(
      "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token",
    );
  }

  headers.common["X-Requested-With"] = "XMLHttpRequest";
  headers.common["Authorization"] = `Bearer ${jwt_token}`;

  return axios.create();
}

export default new Vuex.Store({
  state: {
    isLoading: false,
    SERVER: SERVER,
    routing: null,
    scanData: null,
  },

  getters: {
    scan(state) {
      return state.scanData;
    },

    userData(state) {
      return { name: state.client_name, point: state.client_point };
    },
  },

  actions: {
    async fetchPaths({ state }, _) {
      const response = await API(state["token"]).get(`${SERVER}/api/routes`);
      return response.data;
    },

    async fetchPoint({ state }, params = {}) {
      const response = await API(state["token"]).get(
        `${SERVER}/api/waypoints/${params.waypoint_id}/?route_id=${params.route_id}`,
      );
      return response.data;
    },

    async fetchUserData({ state }, _) {
      const response = await API(state["token"]).get(
        `${SERVER}/api/clients/check/client`,
      );
      
      return response.data;
    },

    async fetchAwardItem({ state }, params = {}) {
      const response = await API(state["token"]).get(
        `${SERVER}/api/shop/get/products?product_id=${params.award_id}`,
      );
      return response.data;
    },

    async fetchAwards({ state }, _) {
      const response = await API(state["token"]).get(`${SERVER}/api/shop/get/products`);

      return response.data;
    },

    async purchaseProduct({ state }, params= {}) {
      const response = await API(state["token"]).post(`${SERVER}/api/shop/product/purchase?product_id=${params.product_id}`)

      return response.data
    },

    async fetchRating({ state }, _) {
      const response = await API(state["token"]).get(`${SERVER}/api/clients/rating`);

      return response.data;
    },

    async fetchPath({ state }, params = {}) {
      const response = await API(state["token"]).get(
        `${SERVER}/api/routes/get/user?route_id=${params.route_id}`,
      );

      return response.data;
    },

    async acceptAgreement({ state }, _) {
      const response = await API(state["token"]).post(`${SERVER}/api/clients/add/client`);

      return response.data;
    },

    async sendQrData({ state }, params = {}) {
      const response = await API(state["token"]).post(
        `${SERVER}/api/waypoints/set/user/waypoint`,
        params,
      );
      return response.data;
    },

    /**
     * @param {Object} context Контекст стора
     * @param {Object} params Параметры запроса на сканирование вирт. точки
     * @param {string} params.route_id id маршрута
     * @param {string} params.waypoint_id id точки
     * @param {string} params.user_position текущие координаты пользователя
     */
    async postScanVirtualPoint({ state }, params = {}) {
      try {
        const response = await API(state.token).post(
          new URL("api/waypoints/set/user/virtual-waypoint", SERVER).href,
          params
        );
        return response.data.data;
      } catch (e) {
        console.error(`Error scanning virtual waypoint`);
        if (e.response) {
          console.error(e.response.data);
          return e.response.data.data;
        } else throw new Error("Ошибка сервера");
      }
    },

    async fetchImage({ state }, params = {}) {
      const api = API(state["token"])
      const response = await api({
        method: 'get',
        url: `${SERVER}/storage/${params.image}`,
        responseType: 'blob'
      });
      const responseBlob = new Blob([response.data], {type: response.headers['content-type']});
      const result64BaseImage = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result)
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(responseBlob);
      });
      return await result64BaseImage;
    },

    /** @returns {Promise<string>} геолокация пользователя в формате "lat long" */
    getCurrentGeolocation
  },

  mutations: {
    setScanResult(state, payload) {
      state.scanData = payload;
    },

    setRouting(state, value) {
      state.routing = value;
    },

    setLoader(state, value) {
      state.isLoading = value;
    },

    setData(state, { path, value }) {
      return set(state, path, value);
    },
  },
});
