import { render, staticRenderFns } from "./Award.vue?vue&type=template&id=d892199c&scoped=true&"
import script from "./Award.vue?vue&type=script&lang=js&"
export * from "./Award.vue?vue&type=script&lang=js&"
import style0 from "./Award.vue?vue&type=style&index=0&id=d892199c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d892199c",
  null
  
)

export default component.exports