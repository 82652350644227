<template>
    <div class="preloader" :class="{preloader_active: isLoading}">
        <div class="preloader-container">
            <span class="preloader__round" :class="{preload__round_loading: isLoading}"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PreLoader",
        props: {
            isLoading: Boolean
        },
        watch: {
            isLoading: function(value) {
                return value;
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../../styles/variables";

    @keyframes preloader-inside-white {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes preloader-inside-red {
    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    30% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.preloader {
    display: none;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
    padding: 20px;

    &_active {
        display: flex;
    }
}

.preloader__container {
    width: 50px;
    height: 50px;
    display: flex;
}

.preloader__round {
    width: 50px;
    height: 50px;
    top: 35%;
    left: 45%;
    position: absolute;
    background: $color-orange-3;
    border-radius: 50px;
}

.preloader__round::after,
.preloader__round::before {
    content: '';
    width: 35px;
    height: 35px;
    position: absolute;
    border-radius: 50px;
}

.preloader__round::after {
    background: $color-white;
    animation: preloader-inside-white 2s ease-in-out infinite;
}

.preloader__round::before {
    z-index: 10;
    background: $color-orange-3;
    animation: preloader-inside-red 2s ease-in-out infinite;
}

.preload__round_loading {
    animation: lds-ring 2s ease-in-out infinite;
    border: 8px solid #fff;
    border-radius: 50%;
    border-color: $color-white transparent;
}
</style>