import { render, staticRenderFns } from "./ResultStatus.vue?vue&type=template&id=7984caa4&scoped=true&"
import script from "./ResultStatus.vue?vue&type=script&lang=js&"
export * from "./ResultStatus.vue?vue&type=script&lang=js&"
import style0 from "./ResultStatus.vue?vue&type=style&index=0&id=7984caa4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7984caa4",
  null
  
)

export default component.exports