var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selected-route"},[_c('div',{staticClass:"selected-route__header"},[(_vm.congratulation)?_c('div',{staticClass:"congratulation-header"},[_c('div',{staticClass:"congratulation-header__title"},[_vm._v("Поздравляем!")]),_c('div',{staticClass:"congratulation-header__status"},[_vm._v("Вы успешно завершили маршрут!")]),_c('div',{staticClass:"congratulation-header__award"},[_vm._v(" Вам начислено "),_c('span',{style:({ color: '#FF8A00' })},[_vm._v(" "+_vm._s(_vm.route.route_type["route_type_point"])+" баллов ")])])]):_c('div',{staticClass:"basic-header"},[_c('div',{staticClass:"basic-header__title"},[_c('h2',[_vm._v(_vm._s(_vm.route.route_title))])]),_c('div',{staticClass:"basic-header__image"},[_c('img',{attrs:{"src":((_vm.$store.state.SERVER) + "/storage/" + (_vm.route.route_image)),"alt":""}})])])]),_c('div',{staticClass:"selected-route__main"},[_c('div',{staticClass:"selected-route__points"},_vm._l((_vm.waypoints),function(point,index){return _c('div',{key:((point.id) + "_" + index),staticClass:"selected-route__point",class:[
          point.is_finished
            ? 'selected-route__point--finished'
            : 'selected-route__point--not-finished' ],on:{"click":function($event){return _vm.goToPoint(point.id)}}},[_c('p',{class:[
          'selected-route__point-name',
          point.is_finished && 'selected-route__point-name--finished'
        ]},[_c('span',{class:[
              'selected-route__point-title',
              point.is_finished
                ? 'selected-route__point-title--finished'
                : 'selected-route__point-title--not-finished' ]},[_vm._v(_vm._s(point.waypoint_title))])]),_c('div',[(!point.is_finished)?_c('div',{style:({color: '#B1B1B1'})},[_vm._v(" > ")]):_c('img',{attrs:{"src":require('../assets/images/check_mark.svg')}})])])}),0)]),_c('div',{staticClass:"selected-route__actions"},[(_vm.congratulation)?_c('div',{staticClass:"selected-route__actions--congratulation"},[_c('button',{staticClass:"selected-route__action button button--orange",on:{"click":function($event){return _vm.$router.push({ name: 'store' })}}},[_vm._v(" Перейти в магазин ")]),_c('button',{staticClass:"selected-route__action button button--outline",on:{"click":function($event){return _vm.$router.push({ name: 'paths' })}}},[_vm._v(" На главную ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }