<template>
  <div class="route-item">
    <div @click="goRoute" class="route-item__inner">
      <div class="route-item__arrow">
        <div class="route-item__title" />

        <div class="route-item__actions">
          <div class="route-item__button">></div>
        </div>
      </div>

      <div class="route-item__content">
        <div class="route-item__left-column">
          <div class="route-item__image">
            <img
              width="180"
              height="120"
              alt=""
              :src="`${$store.state.SERVER}/storage/${route.route_image}`"
            />
          </div>
        </div>
        <div class="route-item__rigth-column">
          <div class="route-item__metrics">
            <p class="route-item__metric">
              Точек: {{ route.waypoints.length }}
            </p>
            <p class="route-item__metric">Минут: {{ route.route_time }}</p>
            <p class="route-item__metric">{{ route.route_distance }} км</p>
          </div>
          <div class="route-item__points">
            <p class="route-item__points-content">
              +{{ route.route_type.route_type_point }}
            </p>
          </div>
        </div>
      </div>
      <div class="route-item__title" v-html="route.route_title" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RouteItem",

  props: {
    route: { type: Object, default: () => ({}) },
  },

  methods: {
    goRoute() {
      this.$emit("go-route", this.route.id);
    },
  },
};
</script>

<style lang="scss">
.route-item {
  position: relative;
  margin-bottom: 20px;
  border-radius: 20px;
  background: #fff;

  box-shadow: 0px 4px 14px rgba(84, 184, 216, 0.25);

  &__inner {
    padding: 13px 15px;
    display: grid;
    gap: 7px;
    height: 100%;
  }

  &__title {
    font-weight: 700;
    word-break: break-word;
    font-size: 18px;
    line-height: 20px;
  }

  &__metrics {
    padding: 4px 10px;
  }

  &__rigth-column {
    padding: 7px 0px 0px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  &__left-column {
  }

  &__image {
    width: 180px;
    height: 120px;
    border-radius: 20px;

    overflow: hidden;
  }

  &__points {
    background: #feb70b;
    border-radius: 20px;
    padding: 4px 8px;
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
  }

  &__points-content {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__content {
    display: flex;
    margin: .5rem;
    gap: 11px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__metric {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #b1b1b1;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__arrow {
    position: absolute;
    top: 0px;
    right: 13px;
  }

  &__button {
    color: #b1b1b1;
    padding: 5px;
    justify-content: flex-end !important;
    border-radius: 50px;

    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
}
</style>
