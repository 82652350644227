<template>
  <div class="path-page">
    <modal-tech-issue v-if="isiOS17" :active="false"></modal-tech-issue>
    <div class="path-page__inner">
      <div class="path-page__header">
        <button
          @click="$router.back()"
          class="paths-page__action--absolute button--default button--default_path-page"
        >
          &lt; Назад
        </button>
      </div>
      <wrapper>
        <div class="path-page__content">
          <selected-route
            :route="route"
            :waypoints="waypoints"
            :congratulation="route.is_finished"
          />
        </div>
      </wrapper>
    </div>
  </div>
</template>

<script>
import Wrapper from "@/components/Wrapper.vue";
import SelectedRoute from "@/components/SelectedRoute.vue";
import ModalTechIssue from "@/components/shared/components/modals/ModalTechIssue.vue";
import { isiOS17 } from "@/services/getiOSVersion.ts"

export default {
  name: "RoutePointsPage",

  components: { Wrapper, SelectedRoute, ModalTechIssue },

  data() {
    return {
      route: {},
      waypoints: null,
    };
  },

  created() {
    this.init();
  },

  computed: {
    isiOS17() {
      return isiOS17()
    }
  },

  methods: {
    async fetchData() {
      const { commit, dispatch } = this.$store;

      try {
        commit("setLoader", true);
        const response = await dispatch("fetchPath", {
          route_id: Number(this.$route.params.id),
        });
        commit("setLoader", false);

        this.route = response.data.route;
        this.waypoints = response.data.waypoints;

      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);
        
      }
    },
    init() {
      this.fetchData();
    },
  },
};
</script>

<style lang="scss">
.path-page {
  height: 100vh;
  padding-top: 84px; //fix-header
  .confetti {
    position: fixed;
    top: 0;
    overflow: hidden;
    z-index: 1;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 45px 17px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 139px;
    background-color: #fff;
    z-index: 10;
  }

  &__title {
    font-weight: bold;
    font-size: 32px;
  }
}
</style>
