<template>
  <div class="main">
    <div class="main__inner">
      <div class="main__header">
        <img
          class="main_img"
          :src="require('../assets/images/iwalk_new_logo.svg')"
          alt=""
        />
      </div>
      <div class="main__content content">
        <div class="content__user">
          <div class="content__user-container">
            <p class="content__score">{{ user_data.client_point }} баллов</p>
          </div>
          <p class="content__title">{{ user_data.client_name }}</p>
        </div>
        <div class="content__buttons">
          <button
            @click="$router.push({ name: 'store' })"
            class="button button--outline"
          >
            Магазин
          </button>
          <button
            @click="$router.push({ name: 'rating' })"
            class="button button--outline content__button"
          >
            <img
              class="content__cup-icon"
              :src="require('../assets/images/cup_icon.svg')"
              alt=""
            />
            Рейтинг
          </button>
        </div>
      </div>
      <div class="main__actions">
        <button
          @click="$router.push({ name: 'paths' })"
          class="button button--positive button--pseudo main__action"
        >
          Продолжить
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "GreetingPage",

  data() {
    return {
      user_data: {},
      position: null
    };
  },

  methods: {
    init() {
      this.fetchUserData();
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          const { latitude, longitude } = coords;
          this.position = [latitude, longitude];
        },
        () => {
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 10000
        }
      );
    },

    async fetchUserData() {
      const { dispatch, commit } = this.$store;
      try {
        commit("setLoader", true);
        const response = await dispatch("fetchUserData");
        commit("setLoader", false);

        if (response.status) {
          this.user_data = response.data;
        }
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);

      }
    },

    dismissError() {
      this.isErrorVisible = false;
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 100vh;
  position: relative;

  &__inner {
    height: 100%;
    padding: 56px 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    color: #0c2248;
    font-weight: 900;
    font-size: 46px;
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  &__header {
    width: 100%;
    min-height: 90px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__subtitle {
    font-size: 30px;
  }

  &__content {
    flex: 1 1 auto;
  }

  .content {
    &__title {
      color: #fff;
      font-size: 29px;
      line-height: 35.1px;
      font-weight: 700;
      max-width: 175px;

      @media screen and (min-width: 600px) {
        & {
          color: black;
        }
      }
    }

    &__cup-icon {
      width: 23px !important;
      height: 23px !important;
    }

    &__title--large {
      font-size: 49px;
      line-height: 59.3px;
    }

    &__user {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 44px;
      padding: 0px 7px;
    }

    &__user-container {
      position: relative;
      min-width: 100px;
    }

    &__user-image {
      width: 100px;
      height: 102px;
      background-color: rgb(206, 206, 206);
      border-radius: 46%;
    }

    &__button {
      display: flex;
      justify-content: center;
      gap: 6px;
      align-items: center;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }

    &__greeting {
      padding: 36px 0 0;
    }

    &__score {
      position: absolute;
      width: 115%;
      background-color: #ff8a00;
      border-radius: 20px;
      padding: 5px 10px;
      color: white;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      transform: translateX(-50%);
      bottom: -12px;
      left: 50%;
    }

    &__points {
      color: #fff;
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
    }
  }

  &__action {
    margin: 0 auto;
    width: 230px !important;
  }
}
</style>
