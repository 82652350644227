var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-description"},[_c('div',{staticClass:"page-description__inner"},[_c('div',{staticClass:"page-description__main"},[_c('div',{staticClass:"page-description__image"},[_c('img',{attrs:{"src":require('../assets/images/iwalk_new_logo.png'),"alt":""}})]),_c('div',{staticClass:"page-description__about-project"},[_c('h2',{style:({
            'font-size': '34px',
            'font-weight': '700',
            'line-height': '41.15px',
          })},[_vm._v(" О проекте ")]),_c('p',{style:({
            'font-size': '23px',
            'font-weight': '400',
            'line-height': '27.84px',
            color: '#343434',
          })},[_vm._v(" Система работает очень просто. Пользователь выбирает маршрут, кликая по нужному названию на виртуальной карте, сервис предоставляет информацию о местных достопримечательностях ")])])]),_c('div',{staticClass:"page-description__footer"},[(!_vm.$store.state.token)?_c('p',{staticClass:"page-description__auth-message"},[_vm._v(" Для использования сервиса требуется авторизация ")]):_vm._e(),(!_vm.$store.state.token)?_c('div',{staticClass:"page-description__action",on:{"click":_vm.sendNeedAuthMessage}},[_c('p',[_vm._v("Авторизоваться")])]):_vm._e(),(!!_vm.$store.state.token)?_c('div',{staticClass:"page-description__action",on:{"click":_vm.goToNextPage}},[_c('p',[_vm._v("Далее")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }