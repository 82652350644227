<template>
  <div class="route-info-page">
    <div class="route-info-page__inner">
      <div class="route-info-page__header">
        <button
          @click="$router.push({ name: 'paths' })"
          class="route-info-page__action--absolute button--default"
        >
          &lt; Назад
        </button>
      </div>

      <wrapper
        class="route-info-page__content"
        :class="[
          route.is_finished ? 'route-info-page__content--congratulation' : '',
        ]"
      >
        <route-description v-if="!route.is_finished" :route-data="route" />

        <div v-else class="congratulation">
          <h1 class="congratulation__title">Поздравляем!</h1>
          <p class="congratulation__text">
            Вы успешно прошли все точки маршрута.
          </p>
          <p class="congratulation__text">Вам начислены бонусные баллы</p>
          <div class="congratulation__points">
            <img
              class="congratulation__coin-image"
              :src="require('../assets/images/coin_icon.svg')"
              alt=""
            />
            <p>+{{ route.route_type.route_type_point }}</p>
          </div>
        </div>
      </wrapper>

      <div v-if="!route.is_finished" class="route-info-page__actions">
        <button
          @click="$router.push({ name: 'routePoints' })"
          class="route-info-page__action button button--positive button--pseudo"
        >
          Начать
        </button>
      </div>

      <div v-else class="route-info-page__actions">
        <button
          class="route-info-page__action button button--outline"
          @click="$router.push({ name: 'main' })"
        >
          На главную
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Wrapper from "@/components/Wrapper.vue";
import RouteDescription from "@/components/RouteDescription.vue";

export default {
  name: "RouteInfoPage",

  components: { Wrapper, RouteDescription },

  data: () => ({
    route: {},
  }),

  created() {
    this.init();
  },

  methods: {
    async fetchData() {
      const { commit, dispatch } = this.$store;
      try {
        commit("setLoader", true);
        const response = await dispatch("fetchPath", {
          route_id: Number(this.$route.params.id),
        });
        commit("setLoader", false);

        this.route = {
          ...response.data.route,
          waypoints: response.data.waypoints,
        };
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);
      }
    },

    init() {
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.route-info-page {
  position: relative;
  height: 100vh;

  &__actions--absolute {
  }

  &__action--absolute {
    margin: 0 auto;
    width: 159px !important;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 139px;
  }

  &__content--congratulation {
    background-image: url("../assets/images/confetti.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
    align-items: center;
    justify-content: center;
  }

  .congratulation {
    max-width: 60%;
    margin-bottom: 150px;

    &__title,
    &__text {
      text-align: center;
      margin-bottom: 22px;
    }

    &__coin-image {
      object-fit: none;
      border: 3px solid #fff;
      border-radius: 50%;
    }

    &__title {
      font-size: 24px;
      font-weight: bold;
    }

    &__text {
      font-size: 17px;
    }

    &__points {
      display: flex;
      gap: 10px;
      background-color: #ff8a00;
      width: max-content;
      padding: 5px 9px;
      align-items: center;
      border-radius: 20px;
      margin: 0 auto;
      font-size: 21px;
      font-weight: 700;
      line-height: 25px;

      p {
        line-height: 1px;
        color: white;
      }
    }
  }

  &__header {
    padding: 100px 17px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 139px;
    background-color: #fff;
    z-index: 10;
  }

  &__actions {
    position: fixed;
    bottom: 56px;
    left: 0;
    width: 100%;
    padding: 0 35px;
    z-index: 10;

    align-items: center;
    display: flex;
    gap: 10px;
  }

  &__action {
    width: 159px !important;
    margin: 0 auto;
  }
}
</style>
