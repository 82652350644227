<template>
  <div class="page-agree">
    <div class="page-agree__inner">
      <div class="page-agree__main">
        <div class="page-agree__image">
          <img
            :src="require('../assets/images/iwalk_new_logo.png')"
            alt=""
          />
        </div>
      </div>
      <div class="page-agree__footer">
        <div class="page-agree__agreement">
          <input
            v-model="agreeChecked"
            :style="{ display: 'none' }"
            type="checkbox"
            id="agree"
          />
          <label for="agree">
            <div
              :class="[agreeChecked ? 'page-agree__checked--active' : '']"
              class="page-agree__checked"
            ></div>
          </label>

          <p
            :style="{
              'font-size': '11px',
              'font-weight': '400',
              'line-height': '13.31px',
              color: '#343434',
            }"
          >
            Вы подтверждаете свое согласие с
            <a href="https://cit.orb.ru/upload/uf/46a/Politika-GKU-TSIT-po-PDn.pdf">политикой конфиденциальности</a> и
            принимаете
            <a href="https://ag.orb.ru/data/news/soflasheniye_2023.pdf"
              >согласие на обработку Ваших персональных данных</a
            >.
          </p>
        </div>
        <button
          @click="acceptAgreement"
          class="page-agree__action"
          :class="[agreeChecked ? '' : 'page-agree__action--gray']"
          :disabled="!agreeChecked"
        >
          <p>Подтвердить участие</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgreePage",

  data: () => ({
    agreeChecked: false,

    user_exists: false,
  }),

  methods: {
    async acceptAgreement() {
      const { commit, dispatch } = this.$store;

      try {
        commit("setLoader", true);
        const response = await dispatch("acceptAgreement");
        commit("setLoader", false);

        if (
          response.data.client_id ||
          response.data === "Пользователь уже существует"
        ) {
          this.$router.push({ name: "profile" });
        } else {
          console.log(response.data.message);
        }
      } catch (e) {
        commit("setLoader", false);
        console.warn("Error:", e);

      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variables";

.page-agree {
  height: 100vh;

  &__title {
    color: #0c2248;
    font-weight: 900;
    font-size: 45px;
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  &__subtitle {
    font-size: 28px;
  }

  &__inner {
    height: 100%;
    padding: 56px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__footer {
    width: 95%;
  }

  &__action {
    position: relative;
    border-radius: 20px;
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #56da39;

    p {
      font-weight: 700;
      line-height: 32.68px;
      font-size: 22px;
      color: #fff;
    }

    &--gray {
      background: $color-gray;
    }
  }

  &__username {
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    color: #fff;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1 1 auto;
    width: 100%;
  }

  &__checked {
    min-width: 41px;
    height: 41px;
    border: 1px solid #56da39;
    border-radius: 7px;

    &--active {
      background-image: url("../assets/images/agree.svg");
      background-position: 60% center;
      background-repeat: no-repeat;
      background-size: 80%;

      background-color: #56da39;
    }
  }

  &__agreement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    margin-bottom: 5px;
    padding: 0 0 15px;
  }
}
</style>
