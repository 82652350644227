<template>
  <div class="award-details">
    <div class="award-details__inner">
      <div class="award-details__header">
        <h2 class="award-details__title" v-if="detail.title">{{ detail.title }}</h2>
        <p v-if="!!detail.subtitle" class="award-details__subtitle" v-html="detail.subtitle">
        </p>
      </div>
      <div class="award-details__content">
        <slot name="award-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AwardDetails",

  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.award-details {

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__subtitle,
  &__content {
    font-size: 15px;
    line-height: 18.15px;
    font-weight: 300;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  &__title {
    font-size: 32px;
    line-height: 38.73px;
    font-weight: 700;
  }
}
</style>
