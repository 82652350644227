import Vue from "vue";
import VueRouter from "vue-router";

import MainPage from "@/views/MainPage";
import DescriptionPage from "@/views/DescriptionPage";
import PathsPage from "@/views/PathsPage";
import AgreePage from "@/views/AgreePage";
import RoutePointsPage from "@/views/RoutePointsPage";
import PointPage from "@/views/PointPage";
import PointInfo from "@/components/PointInfo";
import ScanQr from "@/components/ScanQr";
import ResultPage from "@/views/ResultPage";
import StorePage from "@/views/StorePage";
import AwardPage from "@/views/AwardPage";
import ParticipantPage from "@/views/ParticipantPage";
import RouteInfoPage from "@/views/RouteInfoPage";
import GreetingPage from "@/views/GreetingPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "main",
    path: "/",
    component: MainPage,
    meta: { isCircleBackground: true },
  },
  {
    name: "profile",
    path: "/profile",
    component: GreetingPage,
    meta: { isCircleBackground: true },
  },
  {
    name: "description",
    path: "/description",
    component: DescriptionPage,
    meta: { isCircleBackground: true },
  },
  {
    name: "paths",
    path: "/paths",
    component: PathsPage,
    meta: { isCircleBackground: false },
  },
  {
    name: "rating",
    path: "/rating",
    component: ParticipantPage,
    meta: { isCircleBackground: true },
  },
  {
    name: "agree",
    path: "/agree",
    component: AgreePage,
    meta: { isCircleBackground: true },
  },
  {
    name: "selectedPath",
    path: "/path/:id",
    component: RouteInfoPage,
    meta: { isCircleBackground: false },
  },
  {
    name: "routePoints",
    path: "/path/:id/points",
    component: RoutePointsPage,
    meta: { isCircleBackground: false },
  },
  {
    name: "selectedPoint",
    path: "/path/:id/point/:id",
    component: PointPage,
    meta: { isCircleBackground: false },
    children: [
      {
        name: "point",
        path: "",
        component: PointInfo,
      },
      {
        name: "scan",
        path: "scan",
        component: ScanQr,
      },
      {
        name: "result",
        path: "result",
        component: ResultPage,
      },
    ],
  },
  {
    name: "store",
    path: "/store",
    component: StorePage,
    meta: { isCircleBackground: true },
  },
  {
    name: "storeItem",
    path: "/store/:id",
    component: AwardPage,
    meta: { isCircleBackground: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
