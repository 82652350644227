<template>
  <div class="selected-route">
    <div class="selected-route__header">
      <div v-if="congratulation" class="congratulation-header">
        <div class="congratulation-header__title">Поздравляем!</div>
        <div class="congratulation-header__status">Вы успешно завершили маршрут!</div>
        <div class="congratulation-header__award">
          Вам начислено
          <span :style="{ color: '#FF8A00' }">
            {{ route.route_type["route_type_point"] }} баллов
          </span>
        </div>
      </div>
      <div v-else class="basic-header">
        <div class="basic-header__title">
          <h2>{{ route.route_title }}</h2>
        </div>
        <div class="basic-header__image">
          <img :src="`${$store.state.SERVER}/storage/${route.route_image}`" alt="" />
        </div>
      </div>
    </div>
    <div class="selected-route__main">
      <!-- <div class="selected-route__title">
        <h2>Выберите точку</h2>
      </div> -->
      <div class="selected-route__points">
        <div
          @click="goToPoint(point.id)"
          v-for="(point, index) in waypoints"
          :key="`${point.id}_${index}`"
          :class="[
            point.is_finished
              ? 'selected-route__point--finished'
              : 'selected-route__point--not-finished',
          ]"
          class="selected-route__point">
          <p :class="[
            'selected-route__point-name',
            point.is_finished && 'selected-route__point-name--finished'
          ]">
            <span
              :class="[
                'selected-route__point-title',
                point.is_finished
                  ? 'selected-route__point-title--finished'
                  : 'selected-route__point-title--not-finished',
              ]"
            >{{ point.waypoint_title }}</span>
          </p>
          <div>
            <div v-if="!point.is_finished" :style="{color: '#B1B1B1'}" > > </div>
            <img 
              v-else
              :src="require('../assets/images/check_mark.svg')"
            />
          </div>
        </div>
        
      </div>
    </div>
    <div class="selected-route__actions">
      <div v-if="congratulation" class="selected-route__actions--congratulation">
        <button
          @click="$router.push({ name: 'store' })"
          class="selected-route__action button button--orange">
          Перейти в магазин
        </button>
        <button
          @click="$router.push({ name: 'paths' })"
          class="selected-route__action button button--outline">
          На главную
        </button>
      </div>
      <!-- <div v-else class="selected-route__actions--default">
        <button
          @click="$router.push({ name: 'selectedPath' })"
          class="selected-route__action button button--outline">
          Назад
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectedRoute",

  props: {
    waypoints: { type: Array, default: () => [] },
    route: { type: Object, default: () => {} },
    congratulation: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    goToPoint(pointId) {
      this.$router.push({ path: `/path/${this.route.id}/point/${pointId}` });
    },
  },
};
</script>

<style lang="scss">
.selected-route {
  .basic-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-around;

    &__title {
      font-size: 17px;
      font-weight: 700;
      line-height: 17px;
    }

    &__image {
      width: 100%;
      height: 163px;

      & > img {
        border-radius: 20px;
        overflow: hidden;
      }
    }
  }

  &__title {
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    padding-bottom: 20px;
  }

  &__main {
    padding: 21px 0;
  }

  &__actions {
    position: fixed;
    bottom: 56px;
    width: calc(100% - 45px);
    z-index: 10;

    &--default {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &--congratulation {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
    }
  }

  &__action {
    margin: 0 auto;
    width: 159px !important;
  }

  .congratulation-header {
    display: flex;
    flex-direction: column;
    font-weight: bold;

    &__title {
      font-size: 32px;
      font-weight: bold;
      line-height: 38.73px;
    }

    &__status {
      margin: 0 0 12px;
    }

    &__status,
    &__award {
      font-size: 20px;
    }
  }

  &__points{
    box-shadow: 0px 4px 14px rgba(84, 184, 216, 0.25);

  }

  &__point {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 15px;
    position: relative;
    gap: 20px;

    &:not(:last-child)::before{
      content: "";
      position: absolute;
      width: 90%;
      height: 1px;
      background-color: #E3E3E3;
      bottom: 0
    }


    &--not-finished {
      background: #ffffff;
    }

    &--finished {
      color: #56da39;
      
      * {
        color: #ffffff;
      }
    }
  }

  &__point-title{
    font-weight: 500;

    &--finished{
      color: #56DA39;
    }

    &--not-finished {
      background: #ffffff;
    }
  }

  &__point-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;

    &--finished{
      color: #56DA39;
    }
  }
}
</style>
