<template>
    <div class="modal" :class="{'modal_active': isModalActive}">
        <img :src="photo" alt="photo" 
          class="modal__photo"
          :class="{'modal__photo_active': isModalActive}"
          @click="handleClickPhoto"/>
        <button
          @click="handleClickPhoto"
          class="modal__photo-dismiss"
          :class="{'modal__photo-dismiss_active': isModalActive}"
        >
        </button>
    </div>
</template>

<script>
    export default {
        name: "ModalPhoto",
        props: ['photo'],
        data() {
          return {
            isModalActive: false,
          }
        },
        watch: {
            photo: function(value) {
                return value;
            },
            isModalActive: function(value) {
              return value
            }
        },
        methods: {
            handleClickPhoto() {
              this.isModalActive = !this.isModalActive;
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../../../styles/variables";
.modal {

  position: relative;
  border-radius: 20px;
  &_active {
    &::before {
          content: 'hello';
          display: flex;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-color: $color-black;
          opacity: .5;
        }
  }
  

    &__photo {

      display: flex;
      position: static;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      max-height: 83px;
      max-width: 67px;
      object-fit: contain;

      &_active {
        display: flex;
        position: fixed;
        top: 15%;
        left: 10%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 20px;
        width: 80%;
        min-width: 100px;
        height: 70%;
        min-height: 200px;
        max-height: 90%;
        max-width: 90%;
        z-index: 12;
      }

      &-dismiss {
        display: none;
        position: static;
        background-image: url(../../../../assets/images/close-icon.png);
        width: 50px;
        height: 50px;

        &_active {
          display: flex;
          position: fixed;
          top: 10%;
          right: 10%;
        }
      }
    }
}
</style>