<template>
  <div class="modal" :class="{'modal_active': isModalVisible}">
    <div
    class="modal__container"
    :class="{'modal__container_active': isModalVisible}"
    >
        <p class="modal__container_message">
          {{ message }}
        </p>
        <button
          @click="handleDismissModal"
          class="modal__container_dismiss button button--outline award-page__action--small"
        >
          {{ dismissButtonValue }}
        </button>
    </div>
  </div>
</template>

<script>
    export default {
        name: "ModalWarn",
        props: ['message', 'dismissButtonValue', 'isModalVisible'],
        watch: {
            message: function(value) {
                return value;
            },
            dismissButtonValue: function(value) {
                return value;
            },
            isModalVisible: function(value) {
                return value;
            },
        },
        methods: {
            handleDismissModal(event) {
                this.$emit('modal-dismissed');
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../../../../styles/variables";
.modal {
  position: relative;
  z-index: 20;

  &_active {
    &::before {
          content: 'hello';
          display: flex;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-color: $color-black;
          opacity: .5;
        }
  }

    &__container {

      display: none;
      width: 100%;
      z-index: 21;

      &_active {
        display: flex;
        position: fixed;
        top: 25%;
        left: 10%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-very-light-gray;
        word-wrap: break-word;
        border-radius: 10px;
        padding: 20px;
        width: 80%;
        height: auto;
        min-width: 100px;
        min-height: 200px;
      }

      &_message {
        display: flex;
        word-wrap: break-all;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: $color-red;
        margin-bottom: 20px;
      }

      &_dismiss {
        margin-bottom: 10%;
      }
    }
}
</style>